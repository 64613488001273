<template>
    <div class="eyyb-content">
        <ol class="wrap1200 m-model-select">
            <li :class="{'active': index==1 }" @click="changeIndex(1)">E英语宝（学生）APP</li>
            <li :class="{'active': index==2 }" @click="changeIndex(2)">E英语宝（教师）APP</li>
        </ol>
        <div class="m-studentPart" v-show="index==1">
            <div class="wrap1200">
                <h4 class="student-intro">小学英语、语文、数学全部都在手机里</h4>
                <ol class="student-goods">
                    <li :class="{'active': indexS==index}" v-for="(item, index) in stuArr" :key="index" @click="changeStud(index)"><span></span>{{item.name}}</li>
                    <!-- <li><span></span>作业练习</li>
                    <li><span></span>直播课程</li>
                    <li><span></span>配音秀</li> -->
                </ol>
                <div class="student-showBox posra">
                    <div class="studentImg" @mouseover="clearTimer" @mouseout="countIng">
                        <ul :style="'transform: translateX('+-800*indexS+'px)'">
                            <li v-for="(item, index) in stuArr" :key="index">
                                <img :src="item.src" alt="students" width="">
                            </li>
                        </ul>
                        <div class="bg-studentImg"></div>
                    </div>
                    <div class="eyyb-loadStudents">
                        <p>E英语宝</p>
                        <p>学生APP</p>
                        <img src="../../../assets/images/load-studentApp.png" alt="" width="124" height="124">
                        <p class="size-16">扫码下载</p>
                    </div>
                </div>
            </div>
            <div class="students-modelsInfo posra">
                <div class="bg-gray"></div>
                <ul class="wrap1200 posra">
                    <li>
                        <div class="stu-imgBox">
                            <img src="../../../assets/images/app_01.jpg" alt="" width="360" height="235">
                            <div class="c-shadows">
                                <div class="bg-shadows"></div>
                                <p>支持传统作业在线提交，并提供听/说/读/写等各类形式的练习题库</p>
                            </div>
                        </div>
                        <p class="p-txt">作业形式丰富</p>
                    </li>
                    <li>
                        <div class="stu-imgBox">
                            <img src="../../../assets/images/app_02.jpg" alt="" width="360" height="235">
                            <div class="c-shadows">
                                <div class="bg-shadows"></div>
                                <p>口语评测自动打分，帮你纠正发音、提高口语</p>
                            </div>
                        </div>
                        <p class="p-txt">口语评测，纠正发音</p>
                    </li>
                    <li>
                        <div class="stu-imgBox">
                            <img src="../../../assets/images/app_03.jpg" alt="" width="360" height="235">
                            <div class="c-shadows">
                                <div class="bg-shadows"></div>
                                <p>语数外全配套、全科发展</p>
                            </div>
                        </div>
                        <p class="p-txt">语数外全学科</p>
                    </li>
                    <li>
                        <div class="stu-imgBox">
                            <img src="../../../assets/images/app_04.jpg" alt="" width="360" height="235">
                            <div class="c-shadows">
                                <div class="bg-shadows"></div>
                                <p>看动画、听故事，情境式强化学习，记单词so easy</p>
                            </div>
                        </div>
                        <p class="p-txt">全脑记词，快速记单词</p>
                    </li>
                    <li>
                        <div class="stu-imgBox">
                            <img src="../../../assets/images/app_05.jpg" alt="" width="360" height="235">
                            <div class="c-shadows">
                                <div class="bg-shadows"></div>
                                <p>新奇有趣的漫画，涨知识，每日更新</p>
                            </div>
                        </div>
                        <p class="p-txt">有趣的E宝漫画</p>
                    </li>
                    <li>
                        <div class="stu-imgBox">
                            <img src="../../../assets/images/app_06.jpg" alt="" width="360" height="235">
                            <div class="c-shadows">
                                <div class="bg-shadows"></div>
                                <p>系统提供全方位的学情分析报告，让你了解自己的薄弱项</p>
                            </div>
                        </div>
                        <p class="p-txt">学情报告，分析薄弱项</p>
                    </li>
                </ul>
            </div>
        </div>
        <div class="m-teacherPart clearfix wrap1200"  v-show="index==2">
            <div class="teacherImg" @mouseover="clearTimer" @mouseout="countIng">
                <ul :style="'transform: translateX('+-400*indexT+'px)'">
                    <li v-for="(item, index) in teacherArr" :key="index">
                        <img :src="item.src" alt="teacher" width="">
                    </li>
                </ul>
                <div class="bg-studentImg"></div>
            </div>
            
            <div class="eyyb-teacherR fl">
                <ul>
                    <li :class="{'active': indexT==index}" v-for="(item, index) in teacherArr" :key="index" @click="changeTeacher(index)"><span></span>{{item.name}}</li>
                </ul>
                <div class="eyyb-loadTeacherApp">
                    <p>E英语宝  老师APP</p>
                    <img src="../../../assets/images/load-teacherApp.png" alt="" width="124" height="124">
                    <p class="size-20">扫码下载</p>
                </div>
            </div>
        </div>
    </div>
</template>

<style  lang="scss" scope>
    @import '@/assets/css/pages/eyyb.scss';
</style>

<script>
    import {mapGetters} from "vuex";
    let timer = null;
    export default {
        data () {
            return {
                index: 1,
                indexS: 0,
                indexT: 0,
                stuArr: [{
                    name: '同步教材',
                    src: require('../../../assets/images/eyyb-s1.jpg')
                },{
                    name: '作业练习',
                    src: require('../../../assets/images/eyyb-s2.jpg')
                },{
                    name: '直播课程',
                    src: require('../../../assets/images/eyyb-s3.jpg')
                },{
                    name: '配音秀',
                    src: require('../../../assets/images/eyyb-s4.jpg')
                }],
                teacherArr: [{
                    name: '一键布置作业，自动批改',
                    src: require('../../../assets/images/eyyb-t1.jpg')
                },{
                    name: '精准学生学情',
                    src: require('../../../assets/images/eyyb-t2.jpg')
                },{
                    name: '英语、语文、数学全学科内容覆盖',
                    src: require('../../../assets/images/eyyb-t3.jpg')
                },{
                    name: '最新、权威、海量教研学习视频',
                    src: require('../../../assets/images/eyyb-t4.jpg')
                },{
                    name: '海量积分兑换礼物',
                    src: require('../../../assets/images/eyyb-t5.jpg')
                }]
            } 
        },
        created () {
            this.countIng();
        },
        methods: {
            clearTimer() {
                clearInterval(timer);
            },
            countIng() {
                timer =setInterval(() => {
                    if(this.indexS==this.stuArr.length-1) this.indexS = -1;
                    this.indexS ++
                    if(this.indexT==this.teacherArr.length-1) this.indexT = -1;
                    this.indexT ++
                }, 5000);
            },
            changeIndex(index) {
                this.index = index;
            },
            changeStud(index) {
                this.indexS = index;
            },
            changeTeacher(index) {
                this.indexT = index;
            },
        }
    }
</script>